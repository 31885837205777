import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/css';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../components/Header/Header.scss';
import PrimaryInnerMenu from '../components/Header/PrimaryInnerMenu';
import SecondaryInnerMenu from '../components/Header/SecondaryInnerMenu';
import { AccommodationsData } from '../components/Modules/AccommodationsData';
import Seo from '../components/Seo/Seo';

const PageTemplate = (props) => {
  // Menu
  const menuElement = useRef();
  const menuElementBooking = useRef();

  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  useEffect(() => {
    document.body.classList.remove('mega-menu-open');
    document.body.classList.remove('booking-open');
    document.body.classList.add('form-enquiry-open');
    document.body.classList.remove('menu-open');

    menuElement.current = gsap.timeline({
      paused: true,
      onStart: () => {
        document.body.classList.add('menu-open');
        setMenuOpen(true);
      },
      onReverseComplete: () => {
        document.body.classList.remove('menu-open');
        setMenuOpen(false);
      },
    });
    menuElement.current.to('.nav-container', {
      duration: 0.6,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      right: 0,
      ease: 'Power3.InOut',
    });

    menuElement.current.from(
      '.leftMenu',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );

    menuElementBooking.current = gsap.timeline({
      paused: true,
      onStart: () => {
        document.body.classList.add('booking-open');
      },
      onReverseComplete: () => {
        document.body.classList.remove('booking-open');
        setMenuOpenStatus(false);
      },
    });

    menuElementBooking.current.to('.booking-container', {
      duration: 0.6,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      right: 0,
      ease: 'Power3.InOut',
    });
    menuElementBooking.current.from(
      '.booking-banner-logo',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );
    menuElementBooking.current.from(
      '.booking-info-container',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );
  }, []);
  useEffect(() => {
    menuOpenStatus ? menuElement.current.play() : menuElement.current.reverse();
    menuOpenBooking
      ? menuElementBooking.current.play()
      : menuElementBooking.current.reverse();
  }, [menuOpenStatus, menuOpenBooking]);

  const headerData = useStaticQuery(graphql`
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            primaryLogo {
              altText
              uri
              mediaItemUrl
            }
            directBookingButton {
              target
              title
              url
            }
            socialMedia {
              url
              icon {
                altText
                uri
                mediaItemUrl
              }
            }
            contactInfo
            menuBackgroundImage {
              altText
              uri
              mediaItemUrl
            }
            enquiryMenuBackgroundImage {
              altText
              uri
              mediaItemUrl
            }
            travelEnquiryApi
            travelEnquiryBgimage {
              altText
              mediaItemUrl
              gatsbyImage(width: 1000, formats: WEBP)
              uri
              title
            }
            travelEnquiryFormid
            travelEnquiryLogo {
              altText
              mediaItemUrl
              gatsbyImage(width: 1000, formats: WEBP)
              uri
              title
            }
            travelEnquiryApi
            travelEnquiryBgimage {
              altText
              mediaItemUrl
              gatsbyImage(width: 1000, formats: WEBP)
              uri
              title
            }
            directBookingDescriptions
            directBookingHeading
            travellerEnquiryHeading
            travellerEnquiryDescriptions
            directBookingDescriptions
            directBookingHeading
            travellerEnquiryButton {
              target
              title
              url
            }
          }
        }
      }
    }
  `);

  let acco_data = AccommodationsData();
  acco_data = acco_data.allWpAccommodation.edges;
  const headerDataList = headerData.wp.fluxDnaSettings.fluxDna;

  const [stepForm, setStepForm] = useState(1);
  const [isLadoing, setIsLoading] = useState(false);
  const [nxtDate, setNxtDate] = useState('');
  const [checked, setChecked] = useState(false);
  const [form, setForm] = useState({
    input_11: props.location.state
      ? props.location.state.room === undefined ||
        props.location.state.room === '' ||
        props.location.state.room === null
        ? ''
        : props.location.state.room
      : '',
    input_36: '',
    input_37: '',
    input_39_1: '',
    input_19: '',
    input_20: '',
    input_26: '',
    input_25: '',
    input_28: '',
    input_27: '',
    input_41: '',
    input_31: '',
    input_42: '',
    input_43: '',
  });

  const [formError, setFormError] = useState({
    input_11: '',
    input_36: '',
    input_37: '',
    input_19: '',
    input_20: '',
    input_26: '',
    input_25: '',
    input_28: '',
    input_27: '',
    input_31: '',
  });

  return (
    <>
      <Seo />
      {isLadoing && (
        <div
          id="loading"
          className="loading-spinner
        "
        ></div>
      )}
      <section className="traveller-enquiry-form">
        <div className="flex flex-wrap">
          <div className="lg:w-7/12 w-full closemenu">
            <div
              className="xl:px-80 lg:px-40
             px-20 lg:py-50 pt-20 relative h-screen overflow-y-auto inquiry-form-steps enquiry-left"
            >
              <div className="enquiry-header mb-50">
                <div className="flex flex-wrap items-center justify-between">
                  <Link to="/" className="relative z-999">
                    <img
                      src={headerDataList.travelEnquiryLogo.mediaItemUrl}
                      alt="Logo"
                    />
                  </Link>
                  <div
                    onClick={() => {
                      setMenuOpenStatus(!menuOpenStatus);
                    }}
                    className={`navbar-icon flex items-center cursor-pointer hamburger menu-open-test ${
                      menuOpen ? 'active' : ''
                    }`}
                  >
                    <div className="space-y-2 menu-line">
                      <span className="line w-full mx-auto"></span>
                      <span className="line line-middle w-full"></span>
                      <span className="line w-full mx-auto"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="scroll-form-main">
                <div className="test">
                  <div className="top-info flex flex-wrap items-center justify-between">
                    <h1 className="text-brown text-30 lg:text-34">Guest Enquiry</h1>
                  </div>
                  <div className={`gf-step ${'step_' + stepForm}`}></div>
                  <form className="lg:mt-50 mt-20 enquiry-step-form">
                    {stepForm === 1 && (
                      <div className="form-row">
                        <h6 className="text-greenColor capitalize text-20 lg:text-28 font-body">
                          Select your Accommodation
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20 lgscreen:gap-20">
                          <div className="lg:w-6/12 w-full lg:px-10">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="Jock Main Lodge"
                                  checked={form.input_11 === 'Jock Main Lodge'}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_11: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_11: '',
                                    });
                                  }}
                                  id="sea-studio"
                                />
                                <label
                                  htmlFor="sea-studio"
                                  className="text-dark font-400 text-16"
                                >
                                  Jock Main Lodge
                                </label>
                              </div>
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="Fitzpatrick’s Lodge"
                                  checked={
                                    form.input_11 === 'Fitzpatrick’s Lodge'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      input_11: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_11: '',
                                    });
                                  }}
                                  id="sea-email"
                                />
                                <label
                                  htmlFor="sea-email"
                                  className="text-dark font-400 text-16"
                                >
                                  Fitzpatrick’s Lodge
                                </label>
                              </div>
                            </div>
                            {formError.input_11 && (
                              <div className="error mt-20">
                                <span className="text-red">
                                  {formError.input_11}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {stepForm === 2 && (
                      <div className="form-row">
                        <h6 className="text-greenColor capitalize text-20 lg:text-28 font-body">
                          When will you be arriving?
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20 lgscreen:gap-20">
                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Arrival date*
                                <DatePicker
                                  className="enquiry-date-picker"
                                  selected={form.input_36}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select Date..."
                                  minDate={new Date()}
                                  onChange={(date) => {
                                    setForm({
                                      ...form,
                                      input_36: date,
                                    });

                                    var date = new Date(date);
                                    date.setDate(date.getDate() + 1);
                                    setNxtDate(date);
                                    setFormError({
                                      ...formError,
                                      input_36: '',
                                    });
                                  }}
                                />
                              </label>
                            </div>

                            {formError.input_36 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_36}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pl-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Departure date
                                <DatePicker
                                  selected={form.input_37}
                                  disabled={form.input_36 === '' ? true : false}
                                  minDate={nxtDate}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select Date..."
                                  onChange={(date) => {
                                    setForm({
                                      ...form,
                                      input_37: date,
                                    });
                                    setFormError({
                                      ...formError,
                                      input_37: '',
                                    });
                                  }}
                                />
                              </label>
                            </div>
                            {formError.input_37 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_37}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="lg:w-6/12 w-full">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <div className="radio-bx checkbox-bx">
                              <input
                                type="checkbox"
                                value="Yes"
                                checked={checked}
                                onChange={(e) => {
                                  setChecked(e.target.checked);
                                  setForm({
                                    ...form,
                                    input_39_1: e.target.value,
                                  });
                                }}
                                id="input_39_1"
                              />
                              <label
                                htmlFor="input_39_1"
                                className="w-full text-slatyBlack text-16 font-400"
                              >
                                My travel dates are slightly flexible
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {stepForm === 3 && (
                      <div className="form-row">
                        <h6 className="text-greenColor capitalize text-20 lg:text-28 font-body">
                          Who will be travelling with you?
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20 lgscreen:gap-20">
                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Number of Adults*
                              </label>
                              <select
                                name=""
                                id=""
                                value={form.input_19}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_19: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_19: '',
                                  });
                                }}
                              >
                                <option value="">Select number...</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>

                            {formError.input_19 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_19}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pl-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Number of Children
                              </label>
                              <select
                                name=""
                                value={form.input_20}
                                id=""
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_20: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_20: '',
                                  });
                                }}
                              >
                                <option value="">Select number...</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                            {formError.input_20 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_20}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Ages of Children Travelling
                              </label>
                              <input
                                name=""
                                value={form.input_43}
                                id=""
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_43: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {stepForm === 4 && (
                      <div className="form-row">
                        <h6 className="text-greenColor capitalize text-20 lg:text-28 font-body">
                          Guest information
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20 lgscreen:gap-20">
                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                First Name*
                              </label>
                              <input
                                type="text"
                                value={form.input_26}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_26: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_26: '',
                                  });
                                }}
                                placeholder="Type here..."
                              />
                            </div>

                            {formError.input_26 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_26}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pl-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Last Name*
                              </label>
                              <input
                                type="text"
                                value={form.input_25}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_25: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_25: '',
                                  });
                                }}
                                placeholder="Type here..."
                              />
                            </div>
                            {formError.input_25 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_25}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Phone Number*
                              </label>
                              <input
                                type="number"
                                value={form.input_28}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_28: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_28: '',
                                  });
                                }}
                                placeholder="Type here..."
                              />
                            </div>
                            {formError.input_28 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_28}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pl-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Email Address*
                              </label>
                              <input
                                type="text"
                                value={form.input_27}
                                onBlur={(e) => {
                                  var value = e.target.value;
                                  var pattern =
                                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                  if (!pattern.test(value)) {
                                    setForm({ ...form, input_27: '' });
                                    setFormError({
                                      ...formError,
                                      input_27: 'Please enter valid email.',
                                    });
                                  }
                                }}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_27: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_27: '',
                                  });
                                }}
                                placeholder="Type here..."
                              />
                            </div>
                            {formError.input_27 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_27}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="lg:w-6/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Country travelling from*
                              </label>
                              <select
                                value={form.input_31}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_31: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_31: '',
                                  });
                                }}
                              >
                                <option value="">Select Country</option>
                                <option value="South Africa">
                                  South Africa
                                </option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">
                                  American Samoa
                                </option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">
                                  Antigua and Barbuda
                                </option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire, Sint Eustatius and Saba">
                                  Bonaire, Sint Eustatius and Saba
                                </option>
                                <option value="Bosnia and Herzegovina">
                                  Bosnia and Herzegovina
                                </option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">
                                  Bouvet Island
                                </option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">
                                  British Indian Ocean Territory
                                </option>
                                <option value="Brunei Darussalam">
                                  Brunei Darussalam
                                </option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">
                                  Burkina Faso
                                </option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">
                                  Cayman Islands
                                </option>
                                <option value="Central African Republic">
                                  Central African Republic
                                </option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">
                                  Christmas Island
                                </option>
                                <option value="Cocos Islands">
                                  Cocos Islands
                                </option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo, Democratic Republic of the">
                                  Congo, Democratic Republic of the
                                </option>
                                <option value="Congo, Republic of the">
                                  Congo, Republic of the
                                </option>
                                <option value="Cook Islands">
                                  Cook Islands
                                </option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaçao">Curaçao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">
                                  Czech Republic
                                </option>
                                <option value="Côte d'Ivoire">
                                  Côte d'Ivoire
                                </option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">
                                  Equatorial Guinea
                                </option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Eswatini (Swaziland)">
                                  Eswatini (Swaziland)
                                </option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">
                                  Falkland Islands
                                </option>
                                <option value="Faroe Islands">
                                  Faroe Islands
                                </option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">
                                  French Guiana
                                </option>
                                <option value="French Polynesia">
                                  French Polynesia
                                </option>
                                <option value="French Southern Territories">
                                  French Southern Territories
                                </option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">
                                  Guinea-Bissau
                                </option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard and McDonald Islands">
                                  Heard and McDonald Islands
                                </option>
                                <option value="Holy See">Holy See</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">
                                  Lao People's Democratic Republic
                                </option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">
                                  Liechtenstein
                                </option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">
                                  Marshall Islands
                                </option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Caledonia">
                                  New Caledonia
                                </option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">
                                  Norfolk Island
                                </option>
                                <option value="North Korea">North Korea</option>
                                <option value="Northern Mariana Islands">
                                  Northern Mariana Islands
                                </option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestine, State of">
                                  Palestine, State of
                                </option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">
                                  Papua New Guinea
                                </option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Réunion">Réunion</option>
                                <option value="Saint Barthélemy">
                                  Saint Barthélemy
                                </option>
                                <option value="Saint Helena">
                                  Saint Helena
                                </option>
                                <option value="Saint Kitts and Nevis">
                                  Saint Kitts and Nevis
                                </option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Martin">
                                  Saint Martin
                                </option>
                                <option value="Saint Pierre and Miquelon">
                                  Saint Pierre and Miquelon
                                </option>
                                <option value="Saint Vincent and the Grenadines">
                                  Saint Vincent and the Grenadines
                                </option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">
                                  Sao Tome and Principe
                                </option>
                                <option value="Saudi Arabia">
                                  Saudi Arabia
                                </option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">
                                  Sierra Leone
                                </option>
                                <option value="Singapore">Singapore</option>
                                <option value="Sint Maarten">
                                  Sint Maarten
                                </option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">
                                  Solomon Islands
                                </option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Georgia">
                                  South Georgia
                                </option>
                                <option value="South Korea">South Korea</option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen Islands">
                                  Svalbard and Jan Mayen Islands
                                </option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">
                                  Trinidad and Tobago
                                </option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">
                                  Turkmenistan
                                </option>
                                <option value="Turks and Caicos Islands">
                                  Turks and Caicos Islands
                                </option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="US Minor Outlying Islands">
                                  US Minor Outlying Islands
                                </option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">
                                  United Arab Emirates
                                </option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="United States">
                                  United States
                                </option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Islands, British">
                                  Virgin Islands, British
                                </option>
                                <option value="Virgin Islands, U.S.">
                                  Virgin Islands, U.S.
                                </option>
                                <option value="Wallis and Futuna">
                                  Wallis and Futuna
                                </option>
                                <option value="Western Sahara">
                                  Western Sahara
                                </option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                <option value="Åland Islands">
                                  Åland Islands
                                </option>
                              </select>
                            </div>
                            {formError.input_31 && (
                              <div className="error">
                                <span className="text-red">
                                  {formError.input_31}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:w-6/12 w-full lg:pl-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                How should we contact you?*
                              </label>
                              <select
                                value={form.input_41}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_41: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_41:
                                      'Please select a preferred contact method.',
                                  });
                                }}
                              >
                                <option value="">Select Option...</option>
                                <option value="Email">Email</option>
                                <option value="Phone">Phone</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {stepForm === 5 && (
                      <div className="form-row">
                        <h6 className="text-greenColor capitalize text-20 lg:text-28 font-body">
                          Final Details
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20 lgscreen:gap-20">
                          <div className="lg:w-12/12 w-full lg:pr-20">
                            <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                              <label
                                className="w-full text-slatyBlack text-16 font-400"
                                htmlFor=""
                              >
                                Is there anything else you would like us to
                                know?
                              </label>
                              <textarea
                                rows={4}
                                className="outline-none w-full border-1 border-solid border-[#DBDBDC] mt-20 p-15"
                                value={form.input_42}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_42: e.target.value,
                                  });
                                }}
                                placeholder="Type here..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
                <div
                  className={`form-btn flex  lg:mt-50 mt-20 ${
                    stepForm == 1 ? 'justify-end' : 'justify-between'
                  }`}
                >
                  {stepForm > 1 && (
                    <a
                      href="#"
                      onClick={() => {
                        setIsLoading((isLadoing) => {
                          document.body.classList.add('loading');
                          isLadoing = true;
                        });
                        setStepForm((stepForm) => stepForm - 1);
                        setIsLoading((isLadoing) => {
                          document.body.classList.remove('loader');
                          isLadoing = false;
                        });
                      }}
                      className="cursor-pointer green-btn"
                    >
                      Back
                    </a>
                  )}
                  {stepForm !== 5 ? (
                    <a
                      onClick={() => {
                        if (stepForm === 1) {
                          setFormError({
                            ...formError,
                            input_11:
                              form.input_11 === '' ? 'Please select room.' : '',
                          });

                          if (form.input_11 !== '') {
                            document.body.classList.add('loader');
                            setIsLoading(true);
                            setStepForm((stepForm) => stepForm + 1);
                            document.body.classList.remove('loader');
                            setIsLoading(false);
                          }
                        } else if (stepForm === 2) {
                          document.body.classList.add('loader');
                          setIsLoading(true);
                          setStepForm((stepForm) => stepForm + 1);
                          document.body.classList.remove('loader');
                          setIsLoading(false);
                        } else if (stepForm === 4) {
                          document.body.classList.add('loader');
                          setIsLoading(true);
                          setStepForm((stepForm) => stepForm + 1);
                          document.body.classList.remove('loader');
                          setIsLoading(false);
                        } else if (stepForm === 3) {
                          setFormError({
                            ...formError,
                            input_19:
                              form.input_19 === ''
                                ? 'Please select number of adults.'
                                : '',
                          });

                          if (form.input_19 !== '') {
                            document.body.classList.add('loader');
                            setIsLoading(true);
                            setStepForm((stepForm) => stepForm + 1);
                            document.body.classList.remove('loader');
                            setIsLoading(false);
                          }
                        }
                      }}
                      className="cursor-pointer btn greenOutlinebtn smallBtn"
                    >
                      Continue
                    </a>
                  ) : (
                    <a
                      href="#"
                      onClick={() => {
                        var validRegex =
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                        setFormError({
                          ...formError,
                          input_26:
                            form.input_26 === ''
                              ? 'Please enter first name.'
                              : '',
                          input_41:
                            form.input_41 === ''
                              ? 'Please select a preferred contact method.'
                              : '',
                          input_25:
                            form.input_25 === ''
                              ? 'Please enter last name.'
                              : '',
                          input_28:
                            form.input_28 === ''
                              ? 'Please enter phone number.'
                              : '',
                          input_27:
                            form.input_27 === '' ||
                            !form.input_27.match(validRegex)
                              ? 'Please enter valid email.'
                              : '',
                          input_31:
                            form.input_31 === '' ? 'Please enter country.' : '',
                        });

                        if (
                          form.input_27 !== '' &&
                          form.input_28 !== '' &&
                          form.input_31 !== '' &&
                          form.input_41 !== '' &&
                          form.input_26 !== '' &&
                          form.input_25 !== ''
                        ) {
                          document.body.classList.add('loader');
                          setIsLoading(true);

                          if (form.input_36) {
                            form.input_36 =
                              form.input_36.getMonth() +
                              1 +
                              '/' +
                              form.input_36.getDate() +
                              '/' +
                              form.input_36.getFullYear();
                          }

                          if (form.input_37) {
                            form.input_37 =
                              form.input_37.getMonth() +
                              1 +
                              '/' +
                              form.input_37.getDate() +
                              '/' +
                              form.input_37.getFullYear();
                          }

                          const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(form),
                          };
                          const url = `${process.env.GATSBY_SITE_URL}wp-json/gf/v2/forms/${process.env.GATSBY_ENQUIRY_FORM_ID}/submissions`;
                          setFormError({
                            ...formError,
                            input_41: '',
                          });
                          fetch(url, requestOptions).then((response) => {
                            if (response.status === 200) {
                              document.body.classList.remove('loader');
                              setIsLoading(false);
                              navigate('/thank-you/');
                            } else {
                              setStepForm(1);
                              setFormError({
                                ...formError,
                                input_11: 'Please try again.',
                              });
                            }
                          });
                        }
                      }}
                      className="cursor-pointer btn greenOutlinebtn mdbtn"
                    >
                      Send Enquiry
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 w-full">
            <div className="img inquiry-img">
              <GatsbyImage
                className="w-full h-screen object-cover"
                image={getImage(headerDataList.travelEnquiryBgimage)}
                alt=""
              ></GatsbyImage>
            </div>
          </div>
        </div>
      </section>
      <div className="main-nav nav-container">
        <div className="menu-overlay h-full fixed w-full top-0 left-0 bg-white"></div>
        <div className="mainNavimg hidden md:block">
          <img
            src={headerDataList.menuBackgroundImage.mediaItemUrl}
            className="ml-auto mainNavbgImg"
            alt={headerDataList.menuBackgroundImage.altText}
          />
        </div>
        <div className="mainNav__wrap relative m-auto p-menu_mobile z-99 h-full">
          <div className="container-fluid h-full">
            <div className="mainNav__wrap--inner h-full flex flex-wrap md:flex-nowrap justify-center items-center mobile-menu">
              <div className="md:w-9/12 w-full">
                <div className="mainNav__col leftMenu mainNav__col--left flex flex-wrap items-end relative z-10">
                  <ul className="navMenu navMenuRight hover-underline-solid lg:pt-40">
                    <PrimaryInnerMenu />
                  </ul>
                </div>
              </div>
              <div className="md:w-6/12 w-full ml-auto flex flex-col">
                <div className="mainNav__col navMenu relative mainNav__col--right flex flex-col md:pt-100">
                  <ul className="navMenuLeft visible list-none m-0 md:mr-70 p-0 space-y-5 mdscreen:mb-20">
                    <SecondaryInnerMenu />
                  </ul>
                  <div className="btn-custom bk-btn relative z-999 hidden mdscreen:block mt-20 mb-30">
                    <div
                      onClick={() => {
                        setMenuOpenBooking(!menuOpenBooking);
                      }}
                      className={`button greenOutlinebtn text-center cursor-pointer hamburger menu-open-test transition-all duration-300 large-btn ${
                        menuOpenBooking ? 'active' : ''
                      }`}
                    >
                      Make A Booking
                    </div>
                  </div>
                  <div className="mainNav_Contact--info booking-info-content md:mr-70">
                    <div className="book-contact-info">
                      {parse(headerDataList.contactInfo)}
                    </div>
                    <ul className="inline-flex flex-wrap gap-20 pt-15 items-center">
                      {headerDataList.socialMedia.map((item, i) => (
                        <li>
                          <Link to={item.url}>
                            <img
                              src={item.icon.mediaItemUrl}
                              alt={item.icon.altText}
                            />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-container">
        <div
          onClick={() => {
            setMenuOpenBooking(!menuOpenBooking);
          }}
          className="button close_svg button-darkgray absolute right-[80px] top-[70px] back-button cursor-pointer transition-all duration-300"
        >
          <img src="/images/close.svg" alt="" />
        </div>
        <div className="flex justify-end h-100pr items-center">
          <div className="md:w-6/12 w-full h-100pr mdscreen:hidden">
            <div className="image-wrapper h-100pr">
              <img
                src={headerDataList.enquiryMenuBackgroundImage.mediaItemUrl}
                className="w-100 h-100pr object-cover"
                alt={headerDataList.enquiryMenuBackgroundImage.altText}
              />
              <Link to="/" className="booking-banner-logo">
                <img
                  src={headerDataList.primaryLogo.mediaItemUrl}
                  alt={headerDataList.altText}
                />
              </Link>
            </div>
          </div>
          <div className="md:w-6/12 w-full h-100pr flex items-center smscreen2:items-center justify-center sm:justify-end">
            <div className="xl:px-100 lg:px-50 px-30">
              <div className="booking-info-container text-center sm:text-right border-b-1 border-silver  pb-50 mb-30">
                <div className="title-black">
                  <h3 className="text-brown text-38 leading-74 tracking-006em font-400">
                    {headerDataList.directBookingHeading}
                  </h3>
                </div>
                <div className="content">
                  {parse(headerDataList.directBookingDescriptions)}
                </div>
                <Link
                  to={headerDataList.directBookingButton.url}
                  className="btn greenOutlinebtn mdbtn"
                  target={headerDataList.directBookingButton.target}
                >
                  {headerDataList.directBookingButton.title}
                </Link>
              </div>
              <div className="booking-info-container text-center sm:text-right pb-50 mb-30">
                <div className="title-black">
                  <h3 className="text-brown text-38 leading-74 tracking-006em font-400">
                    {headerDataList.travellerEnquiryHeading}
                  </h3>
                </div>
                <div className="content">
                  {parse(headerDataList.travellerEnquiryDescriptions)}
                </div>
                <Link
                  to={headerDataList.travellerEnquiryButton.url}
                  className="btn greenOutlinebtn mdbtn"
                >
                  {headerDataList.travellerEnquiryButton.title}
                </Link>
              </div>
              <div className="booking-info-container booking-info-content text-center sm:text-right">
                <div className="book-contact-info ">
                  {parse(headerDataList.contactInfo)}
                </div>

                <ul className="flex sm:inline-flex flex-wrap gap-20 pt-15 smscreen2:justify-center items-center">
                  {headerDataList.socialMedia.map((item, i) => (
                    <li>
                      <Link to={item.url}>
                        <img
                          src={item.icon.mediaItemUrl}
                          alt={item.icon.altText}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTemplate;
